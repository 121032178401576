import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paragraph from '../Paragraph';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from '@emotion/styled';
import { Accordion } from '@mui/material';
const StyledAccordian = styled(Accordion)`
    background: #0C1420;
    margin-top: 30px;
    border-radius: 10px !important;
    border:1px solid #1A2940;
    box-shadow: none;  
        & .MuiButtonBase-root{
        border-radius: 10px !important;
        background: #0C1420;
            &>.MuiAccordionSummary-content{
                &>.MuiTypography-root{
                    color:#fff;   
                }
            }
            &>.MuiAccordionSummary-expandIconWrapper{
                &>.MuiSvgIcon-root{
                    color: white;
                }
            }
        }
        &>.MuiCollapse-root{
            &>.MuiCollapse-wrapper{
                &>.MuiCollapse-wrapperInner{
                    &>.MuiAccordion-region{
                        &>.MuiAccordionDetails-root{
                            &>.MuiTypography-root{
                                color:white;
                            }
                        }
                    }
                }
            }
        }
    
`
const BasicAccordion =(props)=> {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = () => () => { 
      setExpanded(!expanded);
    };
  return (
    <div >
      <StyledAccordian onChange={handleChange()} sx={{marginTop: expanded?"30px !important":"0px"}}>
        <AccordionSummary
        expandIcon={expanded ? <RemoveIcon  sx={{fontSize:"20px"}} />: <AddIcon sx={{fontSize:"20px"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header" >
            <Paragraph textAlign="left" my="0px" fontFamily="MontserratSB" >{props.def1}</Paragraph>
        </AccordionSummary>
        <AccordionDetails>
          <Paragraph textAlign="left" my="0px" sx={{marginRight:{xs:"0px", md:"0px"}, fontWeight:'400'}}>
            {props.def2} 
          </Paragraph>
        </AccordionDetails>
      </StyledAccordian>
    </div>
  );
}
export default BasicAccordion