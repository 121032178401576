import React from 'react';
import { Blackbg } from '../../../Styles/Maintext.styles';
import {Container } from '@mui/material';
import Maintxt from '../../Maintxt';
import BasicAccordion from '../../AccordianDesign';


const Faq = () => {
    return (
            <Blackbg data-aos="fade-up" noht noclr id="faq" style={{position:"relative",padding:{xs:"30px 0",sm:"0"}}}>
                {/* <Motioncont maxWidth="lg"  initial={{y:"20%"}} whileInView={{y:"0%"}} viewport={{once: true}} transition={{duration: 1}}  sx={{paddingTop:"50px", paddingBottom:{xs:"70px",lg:"150px"}}}> */} 
                    <Container sx={{paddingTop:"50px", paddingBottom:{xs:"70px",lg:"150px"}, paddingLeft:{xs:"30px",sm:"16px",md:"16px",lg:"16px"},paddingRight:{xs:"30px",sm:"16px",md:"16px",lg:"16px"}}}> 
                        <Maintxt fontSize="clamp(1.875rem, 1.4464rem + 2.1429vw, 3.375rem)" fontFamily="MontserratM"  textAlign="center">BLOCK INN FAQs</Maintxt>
                        <BasicAccordion def1="What is Block Inn?" def2="Block Inn is a blockchain-based platform focused on real estate management and short-term rentals, offering innovative solutions like off-plan investments and automated income distribution for property owners and investors." ></BasicAccordion>
                        <BasicAccordion def1="How do off-plan investments work on Block Inn?" def2="Off-plan investments allow users to invest in properties under development at a lower cost, with the potential for higher returns when the properties are completed and rented out or sold."></BasicAccordion>
                        <BasicAccordion def1="What are the benefits of using Block Inn’s B2B-specific APIs?" def2="Block Inn's B2B-specific APIs enable seamless integration with realtor CRMs and other property management tools, providing real-time property data and automating rental bookings and income distribution."></BasicAccordion> 
                        <BasicAccordion def1="What is the role of the $INN token?" def2="The $INN token is the native cryptocurrency of the Block Inn platform, offering access to exclusive deals, discounts, and staking opportunities that enhance user engagement and investment returns."></BasicAccordion> 
                    </Container>
                {/* </Motioncont> */}   
            </Blackbg>
    );
}
 
export default Faq;