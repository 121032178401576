import React from 'react';
import { Purplediv } from '../../../Styles/Maintext.styles';
import {  Typography} from '@mui/material';


const End = () => {
    return (
        <Purplediv bgclr="transparent"  nobrdr pd="25px">
            <Typography variant="body1" color="white" textAlign="center" fontFamily="MontserratR">
                © 2024 Block Inn. All rights reserved.
            </Typography>
        </Purplediv>
    );
}
 
export default End;