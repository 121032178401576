import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import Regtxt from '../../Regtxt';
import Paragraph from '../../Paragraph';
import LockerMenu from '../../LockerMenu';
import styled from '@emotion/styled'; 

import Marquee from "react-fast-marquee";

const HeaderDesign = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const OurMission = () => { 


  return (  
    <>


    <HeaderDesign id="about">
    <LockerMenu />  
      <Marquee speed={40} style={{width:"100%",background:"transparent"}}>
      <Paragraph  fontFamily="MontserratM"  fontSize={{xs:"100px",sm:"150px"}} margin="0 60px" color="#ffffff2c">Rent, Relax, Repeat—</Paragraph> 
      <Paragraph  fontFamily="MontserratM"  fontSize={{xs:"100px",sm:"150px"}} margin="0 60px" color="#ffffff2c">Rent, Relax, Repeat—</Paragraph> 
      <Paragraph  fontFamily="MontserratM"  fontSize={{xs:"100px",sm:"150px"}} margin="0 60px" color="#ffffff2c">Rent, Relax, Repeat—</Paragraph> 
      <Paragraph  fontFamily="MontserratM"  fontSize={{xs:"100px",sm:"150px"}} margin="0 60px" color="#ffffff2c">Rent, Relax, Repeat—</Paragraph> 
      </Marquee>
      <Container maxWidth="xl"  >
      <Grid container >
        <Grid item xs={12} sx={{padding:"100px 0" ,display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",gap:"10px"}}>
            <Regtxt fs599="33px" my="0px">Our Mission</Regtxt>
            <Box display="flex" flexDirection="column"  justifyContent="center" alignItems="start" gap="10px">  
              <Paragraph variant="h5" padding={{xs:"10px 0",sm:"10px 50px"}} textAlign="center">Block Inn aims to revolutionize the real estate industry by seamlessly blending blockchain technology with property management. Our mission is to provide unparalleled access to short-term rental opportunities and off-plan investments, empowering both individual investors and businesses. By leveraging real-time data, automated income distribution, and integrated B2B APIs, we strive to create a transparent, efficient, and user-centric platform that transforms how properties are scouted, managed, and monetized in the evolving digital economy.</Paragraph>  
            </Box>
        </Grid>
      </Grid>
      </Container>
    </HeaderDesign>
    </>
  )
}

export default OurMission;